<template>
  <div>
    <div class="property-holder">
      <!-- <div class="progress">
        <div
          class="progress-bar w-75"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div> -->
      <!-- <button data-toggle="modal" data-target="#rentsuccess">OPEN</button> -->
      <div class="back-arrow">
        <img @click="moveTo" src="../assets/images/thickbackarrow.svg" alt="back" />
        <span @click="moveTo">Back</span>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="info-header">
            <h5>Property details</h5>
            <p>
              Complete the following fields to provide detailed information
              about the property you wish to add.
            </p>
          </div>
        </div>

        <div class="col-md-6">
          <form>
            <div class="form-group">
              <label for="price">How much is your rent?</label>
              <input
                onfocus="(this.type='text')"
                type="number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                class="form-control"
                id="price"
                name="price"
                v-model="price2"
                placeholder="Enter yearly rent amount"
                maxlength="11"
                :class="{ errorData: info && price === '' }"
              />
            </div>
            <div class="form-group">
              <label for="dateofleaseorrent"
                >When is your currrent rent due?</label
              >
              <input
                type="date"
                class="form-control"
                id="dateofleaseorrent"
                name="dateofleaseorrent"
                v-model="dateofleaseorrent"
                placeholder="When will your rent be due?"
                :class="{ errorData: info && dateofleaseorrent === '' }"
              />
            </div>
            <div
              class="form-group d-flex flex-column align-items-start"
              :class="{ errorData: info && type === '' }"
            >
              <label for="propertytype">What is the property type?</label>
              <div v-for="(item, index) in proprtyTypes" :key="index">
                <div class="form-check pb-3">
                  <input
                    @change="getSubTypeInfo()"
                    class="form-check-input"
                    type="radio"
                    :name="item.type"
                    :id="item.id"
                    v-bind:value="item.id"
                    v-model="type"
                  />
                  <label class="form-check-label radio-label" :for="item.type">
                    {{ item.type }}
                  </label>
                </div>
              </div>
              <!-- <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="propertytype"
                  id="commercial"
                  checked
                />
                <label class="form-check-label radio-label" for="commercial">
                  Commercial
                </label>
              </div> -->
            </div>
            <div class="form-group">
              <label for="subtype">What is the property subtype?</label>
              <select
                class="form-control"
                id="subtype"
                v-model="subtype"
                :class="{ errorData: info && subtype === '' }"
              >
                <option
                  selected
                  disabled
                  value=""
                  style="color: #AAADBA !important;"
                  >--Select--</option
                >
                <option
                  v-for="(subType, index) in proprtySubTypes"
                  :key="index"
                  :value="subType"
                  >{{ subType.subtype }}</option
                >
              </select>
            </div>
            <div class="form-group" v-if="type == '1'">
              <label for="bedrooms">How many rooms does it have?</label>
              <select
                class="form-control"
                id="bedrooms"
                v-model="bedrooms"
                :class="{ errorData: info && bedrooms === '' }"
              >
                <option
                  selected
                  disabled
                  value=""
                  style="color: #AAADBA !important;"
                  >--Select--</option
                >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option
                >s
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group d-none">
                  <label for="type">Property types</label>
                  <select
                    class="form-control"
                    id="type"
                    v-model="type"
                    @change="getSubTypeInfo()"
                    :class="{ errorData: info && type === '' }"
                  >
                    <option
                      selected
                      disabled
                      value=""
                      style="color: #AAADBA !important;"
                      >--Select--</option
                    >
                    <option
                      v-for="(type, index) in proprtyTypes"
                      :key="index"
                      v-bind:value="type.id"
                      >{{ type.type }}</option
                    >
                  </select>
                </div>
                <div class="form-group">
                  <label for="state">State</label>
                  <select
                    @change="getCities()"
                    class="form-control"
                    id="state"
                    v-model="stateid"
                    :class="{ errorData: info && stateid === '' }"
                  >
                    <option
                      selected
                      disabled
                      value=""
                      style="color: #AAADBA !important;"
                      >--Select state--</option
                    >
                    <option
                      v-for="(item, index) in states"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="city">Location</label>
                  <select
                    class="form-control"
                    id="city"
                    v-model="cityid"
                    :class="{ errorData: info && cityid === '' }"
                  >
                    <option
                      selected
                      disabled
                      value=""
                      style="color: #AAADBA !important;"
                      >--Select city--</option
                    >
                    <option
                      v-for="(item, index) in cities"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group property-button">
              <!-- @click.prevent="addProperty()" -->
              <button
                type="button"
                class="btn"
                :disabled="loading"
                @click.prevent="addProperty"
              >
                Add property
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="loading"
                ></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="mt-5"></div>
    <!-- Success modal begins -->
    <div
      tabindex="-1"
      class="modal fade"
      id="rentsuccess"
      role="dialog"
      aria-labelledby="rentsuccess"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <!-- <div class="modal-header"></div> -->
          <div class="modal-body confirmation-body">
            <img src="../assets/images/green-check-round.svg" alt="" />
            <h5>Property added successfully</h5>
            <p>
              Your property has been created. To view and manage it, simply head
              over to <span style="color: #0033EA">Rented.</span> Take advantage
              of our convenient rent financing options. Apply now and easily pay
              for your property with ease!
            </p>
          </div>
          <div class="modal-footer modal-button-confirm">
            <button
              @click="routeTo('rentfinancing')"
              type="button"
              class="btn financing big"
              data-dismiss="modal"
            >
              Pay Your Rent Now
              <br />
              <small>(Rent Financing Available)</small>
            </button>
            <button
              @click="routeTo('paysafe')"
              type="button"
              class="btn safe"
              data-dismiss="modal"
            >
              Plan Your Rent (With SAFE)
            </button>
            <button
              @click="routeTo('cancel')"
              type="button"
              class="btn continue"
              data-dismiss="modal"
            >
              Close & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Success modal ends -->
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "AddPropertyTenant",
  // props: {
  //   properties
  // },

  components: {},
  data() {
    return {
      loading: false,
      info: false,
      proprtyTypes: [],
      proprtySubTypes: [],
      states: [],
      stateid: "",
      address: "",
      proprtySubTypes: [],
      subtype: "",
      subtypename: "",
      proprtyTypes: [],
      type: "",
      bedrooms: "",
      title: "",
      price: "",
      price2: "",
      dateofleaseorrent: "",
      userid: "",
      cities: "",
      cityid: ""
    };
  },
  watch: {
    price2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.price2 = result));
      this.price = this.price2.split(",").join("");
      // console.log("Price", this.price);
    }
  },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  mounted() {
    this.userid = JSON.parse(localStorage.getItem("shelta-user")).id;
    // this.getStates();
    this.getCurrentDay();
    this.getPropertyTypes();
  },
  methods: {
    moveTo() {
      this.$router.go(-1);
    },
    getCurrentDay() {
      const today = new Date().toISOString().split("T")[0];
      document.getElementById("dateofleaseorrent").setAttribute("min", today);
    },
    routeTo(item) {
      if (item === "rentfinancing") {
        Api.pushPropertyDetails(this.propertySuccessData.property);
        return this.$router.push({
          path: `/dashboard/applyrentfinancing/${this.propertySuccessData.property.pin}`
        });
      }
      if (item === "paysafe") {
        // console.log("property details to push", this.propertySuccessData);
        Api.pushPropertyDetails(this.propertySuccessData.property);
        localStorage.setItem("scheduleSafe", this.propertySuccessData.property.id);
        return this.$router.push({
          path: `/dashboard/newproject`
        });
      }
      if (item === "cancel") {
        return this.$router.push({
          path: `/dashboard/rented`
        });
      }
    },
    async addProperty() {
      this.loading = true;
      this.info = true;
      if (this.price === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Enter Rent Amount");
      }
      // if (this.price < 200000) {
      //   this.info = true;
      //   this.loading = false;
      //   return this.$store.commit("setApiFailed", "Mininum rent amount must be 200,000");
      // }
      if (this.dateofleaseorrent === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "select rent due date");
      }
      if (this.type === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select property type");
      }
      if (this.subtype === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select property sub type");
      }
      if (this.bedrooms === "" && this.type == "1") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "select bedrooms");
      }
      if (this.stateid === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select state");
      }
      if (this.cityid === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select city");
      }
      if (this.type == "2") {
        this.bedrooms = "";
      }
      try {
        await Api.postRequest(`tenantaddpropertysimplified`, {
          title: `${this.bedrooms} ${"bedroom"} ${this.subtype.subtype}`,
          price: parseInt(this.price),
          // address: this.address,
          stateid: parseInt(this.stateid),
          cityid: parseInt(this.cityid),
          bedrooms: parseInt(this.bedrooms),
          type: this.type,
          subtype: this.subtype.id,
          userid: this.userid,
          dateofleaseorrent: this.dateofleaseorrent
        })
          .then(res => {
            this.loading = false;
            // Api.pushPropertyDetails(res.data);
            // console.log("added property", res.data);
            this.propertySuccessData = res.data;
            if (res.data.success) {
              $("#rentsuccess")
                .addClass("fade")
                .modal("show");
              // this.$router.push({
              //     path: `/dashboard/rented`
              // });
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    async getPropertySubTypes() {
      const id = this.type;
      this.$store.commit("setApiWarning", "Loading property subtype...");
      try {
        await Api.getRequest(`fetchpropertysubtypesbytypeid/${id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.proprtySubTypes = res.data.propertysubtypes;
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    async getPropertyTypes() {
      // this.$store.commit("setApiWarning", "Loading property types...");
      try {
        // fetchpropertytypes
        await Api.getRequest(`fetchpropertytypessimplified`)
          .then(res => {
            if (res.data.success) {
              this.proprtyTypes = res.data.propertytypes;
              this.getStates();
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getSubTypeInfo(event) {
      this.getPropertySubTypes();
    },
    async getStates() {
      // const id = this.countryId;
      try {
        await Api.getRequest(`fetchstatesbycountryid/${161}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.states = res.data.states;
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getCities: async function() {
      const id = this.stateid;
      // this.$store.commit("setApiWarning", "Loading cities");
      try {
        await Api.getRequest(`fetchcitiesbystateid/${id}`)
          .then(res => {
            // this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.cities = res.data.cities;
              // return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    async getCountries() {
      // this.$store.commit("setApiWarning", "Loading countries...");
      try {
        await Api.getRequest(`fetchallcountries`)
          .then(res => {
            console.log("contry_id", res);
            if (res.data.success) {
              this.countries = res.data.countries;
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$black: #000000;
$sheltaBlack: #000a2f;
$whiteText: #ffffff;
$label: #57595a;
$gray2: #555c74;
$error: #dc3545;
$grey3: #aaadba;

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

.property-holder {
  margin: 40px 15px;
  padding: 40px 24px;
  border-radius: 15px;
  background: $whiteText;
}

.back-arrow {
  display: flex;
  cursor: pointer;
  span {
    padding-left: 8px;
    color: $sheltaBlack;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    cursor: pointer;
  }
}

.info-header {
  h5 {
    padding-top: 40px;
    color: $sheltaBlack;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-align: left;
  }
  p {
    color: $gray2;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
}

.radio-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: $sheltaBlack;
  // display: flex;
  box-shadow: none;
  outline: 0;
  padding-left: 10px;
}

input[type="radio"] {
  // transform:scale(1.5);
  width: 20px;
  height: 20px;
}
input[type="select"],
input[type="text"],
input[type="number"],
input[type="date"],
input[type="email"],
select {
  height: 48px;
  border: 0.7px solid #e5e5e5;
  border-radius: 5px;
}
.form-group > label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  // line-height: 30px;
  display: flex;
  color: $sheltaBlack;
  margin-bottom: 4px;
}
.form-group {
  margin-bottom: 32px;
  select,
  input {
    // height: 40px;
    border: 0.7px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: $grey3;
    // text-transform: capitalize;
  }
  textarea {
    // text-transform: lowercase;
    border: 0.7px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: $grey3;
  }
}
.errorData {
  border: 0.5px solid $error !important;
}

.property-button {
  margin-top: 32px;
  button {
    background: $primary;
    color: $whiteText;
    width: 178px;
    height: 48px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    outline: 0;
    box-shadow: none;
  }
}

// Confirmation modaly
.confirmation-body {
  padding: 24px 40px 40px;
  @media only screen and (max-width: 599px) {
    padding: 24px 20px 40px;
  }
  img {
    padding-top: 8px;
    width: 64px;
  }
  h5 {
    padding-top: 24px;
    color: $sheltaBlack;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  p {
    color: $label;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
  }
}

.big {
  height: auto !important;
  small {
    font-size: 14px;
  }
}
.modal-button-confirm {
  border-top: none;
  margin: 0 40px 40px;
  button {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    height: 48px;
    width: 100%;
  }
  .financing {
    background: $primary;
    color: $whiteText;
  }
  @media only screen and (max-width: 599px) {
    margin: 0 20px 40px;
    padding: 0;
  }
  .safe {
    background: $whiteText;
    color: $primary;
    border: 1px solid $primary;
  }
  .continue {
    background: $whiteText;
    color: $primary;
  }
}
// Confirmation modaly

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $grey3;
  font-family: Lato;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $grey3;
  font-family: Lato;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $grey3;
  font-family: Lato;
}
</style>
